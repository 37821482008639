<template>
  <div class="container">
    <div class="head">
      <img src="../../assets/images/yuwan/moveHead.jpg" />
    </div>
    <div class="content">
      <div class="baseInfo">
        <div class="timeContent">
          <div
            class="timeBox"
            v-for="(item, itemName, index) in timeData"
            :key="index"
          >
            <div class="timeRow">
              <div class="timeTitle">
                <div class="baseTitle1" style="border-radius: 8px 8px 0 0">
                  赛前练习预约（5G商用设备赛）
                </div>
                <div class="baseTitle1" style="border-radius: 0 0 8px 8px">
                  地点：<span class="timeAddress">
                    {{ item[index].area }} - {{ item[index].place }}
                    <!-- 武汉职业技术学院-工业中心2016 -->
                  </span>
                </div>
              </div>

              <div class="outTimeContent">
                <div
                  class="timeCont"
                  v-for="(arr, i) in item"
                  :key="i"
                  @click="select(arr, i)"
                  :class="{
                    selected: isChange == arr.onlyIndex,
                    defBgc: !arr.remain_number || arr.remain_number == 0,
                  }"
                >
                  <div class="timeMessage dataT">{{ arr.dayTime }}</div>
                  <div class="timeMessage dataT">{{ arr.dataTime }}</div>
                  <div class="timeMessage">余{{ arr.remain_number || 0 }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="inquireBtn1" type="primary">
        <span @click="next()">确认预约</span>
      </div>
    </div>
  </div>
</template>
 
<script>
import { ElMessage } from "element-plus";
import { moveAppointment } from "api/apis.js";
export default {
  data() {
    return {
      timeData: {},
      activeColor: "#fff",
      selectedItems: [],
      isChange: -1,
      place_time_id: "",
      team_id: "",
      iphone: "",
      track: "",
      group: "",
      province: "",
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      // this.iphone = this.$route.query.iphone;
      // this.dataList = [];
      this.team_id = localStorage.getItem("team_id");
      this.iphone = localStorage.getItem("iphone");
      this.track = localStorage.getItem("track");
      this.group = localStorage.getItem("group");
      this.province = localStorage.getItem("province");
      let dataobj = new FormData();
      dataobj.append("phone", this.iphone);
      dataobj.append("team_id", this.team_id);
      dataobj.append("type", "国赛");
      dataobj.append("track", this.track);
      dataobj.append("group", this.group);
      moveAppointment.place_time(dataobj).then((res) => {
        console.log(JSON.parse(res.info));
        if (res.code === 200) {
          let data = JSON.parse(res.info);
          if (data.length == 0) {
            let idList = localStorage.getItem("idList");
            ElMessage({
              message: "当前无可预约时间",
              type: "warning",
            });
            this.$router.push({
              path: "/appointment",
              query: { iphone: this.iphone, idList: idList },
            });
          }
          this.timeData = {};
          data.forEach((item, index) => {
            item.onlyIndex = index + 1;
            item.dayTime = item.begin_time.split(" ")[0];
            item.dataTime =
              item.begin_time.split(" ")[1] + "-" + item.end_time.split(" ")[1];
            //时间小于12点为上午，可以选中
            // item.timeStatus =
            //   Number(item.dataTime.split("-")[1].split(":")[0]) <= 12
            //     ? true
            //     : false;
            // item.remain_number = null
            if (this.timeData[item.place_id]) {
              this.timeData[item.place_id].push(item);
              this.timeData[item.place_id].sort(function (a, b) {
                return b.begin_time < a.begin_time ? 1 : -1;
              });
            } else {
              this.timeData[item.place_id] = [item];
            }
          });
        }
      });
    },
    select(arr, index) {
      console.log(arr);
      if (Number(arr.remain_number) > 0) {
        if (arr.onlyIndex != this.isChange) {
          this.isChange = arr.onlyIndex;
          this.place_time_id = arr.id;
        } else {
          this.isChange = -1;
          this.place_time_id = "";
        }
      }
    },
    next() {
      let dataobj = new FormData();
      dataobj.append("phone", this.iphone);
      dataobj.append("team_id", this.team_id);
      dataobj.append("place_time_id", this.place_time_id);
      moveAppointment.save(dataobj).then((res) => {
        if (res.code === 200 || res.code === 201) {
          let idList = localStorage.getItem("idList");
          ElMessage({
            message: res.msg,
            type: "success",
          });
          this.$router.push({
            path: "/appointment",
            query: { iphone: this.iphone, idList: idList },
          });
        } else if (res.code === 0) {
          ElMessage({
            message: res.msg,
            type: "error",
          });
          window.location.reload();
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "./appointment.scss";
</style>